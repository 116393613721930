import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { environment } from 'projects/shared/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DmrService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService
  ) {}

  // This can be called with or without auth check - deafult is true
  getVehicleByRegNumber(
    regNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Check if we should check for auth token
    if (requireAuth) {
      // Check for an active token
      const myToken = this.loginService.getActiveToken();
      if (!myToken) {
        console.error('Error with token in getVehicleByRegNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null); // Return an observable with a null value if no token
      }
      // If a token is available, proceed with the API call
      headers = headers.set('Authorization', `Bearer ${myToken}`);
    }

    return this.http
      .get<any>(`${environment.apiGetVehicleFromDMRByReg}${regNumber}`, {
        headers,
      })
      .pipe(
        map((result) => {
          if (requireAuth) {
            this.loginService.checkForRenewedToken(result);
          }
          return result;
        }),
        catchError((error) => {
          // Handle errors, particularly 401 Unauthorized (Expired Token)
          if (requireAuth && error.status === 401) {
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
          }
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  // This can be called with or without auth check - deafult is true

  getVehicleByVINNumber(
    vinNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Check if we should check for auth token
    if (requireAuth) {
      // Check for an active token

      const myToken = this.loginService.getActiveToken();
      if (!myToken) {
        console.error('Error with token in getVehicleByVINNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null); // Return an observable with a null value if no token
      }

      // If a token is available, proceed with the API call
      headers = headers.set('Authorization', `Bearer ${myToken}`);
    }

    return this.http
      .get<any>(`${environment.apiGetVehicleFromDMRByVIN}${vinNumber}`, {
        headers,
      })
      .pipe(
        map((result) => {
          if (requireAuth) {
            // Check if the response contains a renewed token attribute
            // If found, update the active token
            this.loginService.checkForRenewedToken(result);
          }
          return result;
        }),
        catchError((error) => {
          // Handle errors, particularly 401 Unauthorized (Expired Token)
          if (requireAuth && error.status === 401) {
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
          }
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  // This can be called with or without auth check - deafult is true
  scrapeVehicleByRegNumber(
    regNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Check if we should check for auth token
    if (requireAuth) {
      // Check for an active token
      const myToken = this.loginService.getActiveToken();

      if (!myToken) {
        console.error('Error with token in scrapeVehicleByRegNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null); // Return an observable with a null value if no token
      }
      // If a token is available, proceed with the API call
      headers = headers.set('Authorization', `Bearer ${myToken}`);
    }

    return this.http
      .get<any>(`${environment.apiScrapeVehicleFromDMRByReg}${regNumber}`, {
        headers,
      })
      .pipe(
        map((result) => {
          if (requireAuth) {
            // Check if the response contains a renewed token attribute
            // If found, update the active token
            this.loginService.checkForRenewedToken(result);
          }
          return result;
        }),
        catchError((error) => {
          // Handle errors, particularly 401 Unauthorized (Expired Token)
          if (requireAuth && error.status === 401) {
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
          }
          console.error(error);
          return throwError(() => error);
        })
      );
  }

  // This can be called with or without auth check - deafult is true
  scrapeVehicleByVINNumber(
    vinNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Check if we should check for auth token
    if (requireAuth) {
      // Check for an active token
      const myToken = this.loginService.getActiveToken();

      if (!myToken) {
        console.error('Error with token in scrapeVehicleByVINNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null); // Return an observable with a null value if no token
      }
      // If a token is available, proceed with the API call
      headers = headers.set('Authorization', `Bearer ${myToken}`);
    }

    return this.http
      .get<any>(`${environment.apiScrapeVehicleFromDMRByVIN}${vinNumber}`, {
        headers,
      })
      .pipe(
        map((result) => {
          if (requireAuth) {
            // Check if the response contains a renewed token attribute
            // If found, update the active token
            this.loginService.checkForRenewedToken(result);
          }
          return result;
        }),
        catchError((error) => {
          // Handle errors, particularly 401 Unauthorized (Expired Token)
          if (requireAuth && error.status === 401) {
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
          }
          console.error(error);
          return throwError(() => error);
        })
      );
  }
}
