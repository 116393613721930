import { Component } from '@angular/core';
import {
  Dealer,
  DealerService,
  DmrResponse,
  DmrService,
  HelvetiaPriceRequest,
  HelvetiaService,
  HelvetiaVariant,
  LibUtilityService,
} from 'projects/shared/src/public-api';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { ResetPriceCalculator } from './services/reset-price-calculator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // Animation for showing and hiding the dropdown menu
    trigger('fadeInOut', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(100)),
    ]),
    // Animation for show steps
    trigger('fadeInOutStep', [
      state(
        'void',
        style({
          opacity: 0,
        }),
      ),
      transition('void <=> *', animate(250)),
    ]),
  ],
})
export class AppComponent {
  constructor(
    private dmrService: DmrService,
    private helvetiaService: HelvetiaService,
    private libUtilityService: LibUtilityService,
    public dealerService: DealerService,
    private resetPriceCalculator: ResetPriceCalculator,
  ) {}

  title: string = '';

  activeStep: number = 0;
  numberOfSteps = 7;
  requireAuth = false;

  errorInHelvetiaPrice: boolean = false;
  errorInZipCode: boolean = false;
  errorInRegOrVinNumber: boolean = false;
  errorInCarPrice: boolean = false;
  errorInDealer: boolean = false;
  errorInCustomerAge: boolean = false;
  errorInPurchasedOrLeased: boolean = false;
  errorIngetInsurancePrices: boolean = false;

  loadingDmrScrape: boolean = false;
  loadinginsuranceInfo: boolean = false;
  loadingRegOrVinNumber: boolean = false;
  loadingDealers: boolean = false;

  showYearlyPrice: boolean = false;
  isZipcodeValid: boolean = false;
  isCustomerAgeValid: boolean = false;
  receivedDMRVehicle: boolean = false;
  regOrVinNumberInputFocused: boolean = false;
  specialCarError: boolean = false;
  allHaveTogglePricetag: boolean = false;

  zipCode: number | null = null;
  regOrVinNumber = '';
  carOwnershipType = '';
  customerAge: number | null = null;
  selectedPrice: { name: string; value: number } = { name: '', value: 0 };
  selectedDealer: { name: string; value: number } = { name: '', value: 0 };
  dealers: Dealer[] = [];
  dropdown_dealers: { name: string; value: number }[] = [];
  helvetiaVariants: HelvetiaVariant[] = [];
  selectedHelvetiaVariant: HelvetiaVariant = {} as HelvetiaVariant;
  helvetiaMonthlyPrice: number = 0;
  helvetiaYearlyPrice: number = 0;
  vehicleFromDmr: DmrResponse = {} as DmrResponse;

  dropdown_carPrice = [
    { name: '0 - 50.000 kr.', value: 50000 },
    { name: '50.001 - 100.000 kr.', value: 100000 },
    { name: '100.001 - 150.000 kr.', value: 150000 },
    { name: '150.001 - 200.000 kr.', value: 200000 },
    { name: '200.001 - 250.000 kr.', value: 250000 },
    { name: '250.001 - 300.000 kr.', value: 300000 },
    { name: '300.001 - 350.000 kr.', value: 350000 },
    { name: '350.001 - 400.000 kr.', value: 400000 },
    { name: '400.001 - 450.000 kr.', value: 450000 },
    { name: '450.001 - 500.000 kr.', value: 500000 },
    { name: '500.001 - 550.000 kr.', value: 550000 },
    { name: '550.001 - 600.000 kr.', value: 600000 },
    { name: '600.001 - 650.000 kr.', value: 650000 },
    { name: '650.001 - 700.000 kr.', value: 700000 },
    { name: '700.001 - 750.000 kr.', value: 750000 },
    { name: '750.001 - 800.000 kr.', value: 800000 },
    { name: '800.001 - 850.000 kr.', value: 850000 },
    { name: '850.001 - 900.000 kr.', value: 900000 },
    { name: '900.001 - 950.000 kr.', value: 950000 },
    { name: '950.001 - 1.000.000 kr.', value: 1000000 },
    { name: '1.000.001 - 1.050.000 kr.', value: 1050000 },
    { name: '1.050.001 - 1.100.000 kr.', value: 1100000 },
    { name: '1.100.001 - 1.150.000 kr.', value: 1150000 },
    { name: '1.150.001 - 1.200.000 kr.', value: 1200000 },
    { name: '1.200.001 - 1.250.000 kr.', value: 1250000 },
    { name: '1.250.001 - 1.300.000 kr.', value: 1300000 },
    { name: '1.300.001 - 1.350.000 kr.', value: 1350000 },
    { name: '1.350.001 - 1.400.000 kr.', value: 1400000 },
    { name: '1.400.001 - 1.450.000 kr.', value: 1450000 },
    { name: '1.450.001 - 1.500.000 kr.', value: 1500000 },
    { name: '1.500.001 - 1.550.000 kr.', value: 1550000 },
    { name: '1.550.001 - 1.600.000 kr.', value: 1600000 },
    { name: '1.600.001 - 1.650.000 kr.', value: 1650000 },
    { name: '1.650.001 - 1.700.000 kr.', value: 1700000 },
    { name: '1.700.001 - 1.750.000 kr.', value: 1750000 },
    { name: '1.750.001 - 1.800.000 kr.', value: 1800000 },
    { name: '1.800.001 - 1.850.000 kr.', value: 1850000 },
    { name: '1.850.001 - 1.900.000 kr.', value: 1900000 },
    { name: '1.900.001 - 1.950.000 kr.', value: 1950000 },
    { name: '1.950.001 - 2.000.000 kr.', value: 2000000 },
  ];

  reset() {
    // Reset this components data
    this.resetBaseInfo();
    // reset all data in the app-helvetia-price-calculator component in this app
    this.resetPriceCalculator.resetPriceCalculatorValues.next('');
  }

  resetBaseInfo() {
    this.activeStep = 0;
    this.numberOfSteps = 7;

    this.errorInZipCode = false;
    this.isZipcodeValid = false;
    this.errorInCustomerAge = false;
    this.isCustomerAgeValid = false;

    this.showYearlyPrice = false;
    this.helvetiaVariants = [];
    this.selectedHelvetiaVariant = {} as HelvetiaVariant;

    this.helvetiaMonthlyPrice = 0;
    this.helvetiaYearlyPrice = 0;
    this.vehicleFromDmr = {} as DmrResponse;
    this.receivedDMRVehicle = false;

    this.carOwnershipType = '';
    this.selectedPrice = { name: '', value: 0 };

    this.selectedDealer = { name: '', value: 0 };

    this.customerAge = null;
    this.zipCode = null;
  }

  nextStep() {
    ++this.activeStep;
  }

  dmrInfo() {
    // We this if you allready sercach for a car, and insertet the basic info, and deside to go back.
    this.reset();

    this.specialCarError = false;
    this.receivedDMRVehicle = false;
    // 7 reg - vin over 7
    this.regOrVinNumber = this.regOrVinNumber.replace(/\s/g, '');

    if (this.regOrVinNumber.length < 6) {
      this.errorInRegOrVinNumber = true;
      return;
    }

    this.errorInRegOrVinNumber = false;

    // Reg number
    if (this.regOrVinNumber.length == 7) {
      this.loadingRegOrVinNumber = true;
      this.dmrService
        .getVehicleByRegNumber(this.regOrVinNumber, this.requireAuth)
        .subscribe({
          next: (result: any) => {
            this.vehicleFromDmr = result.vehicle;
            this.loadingRegOrVinNumber = false;

            if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
              this.receivedDMRVehicle = false;
              this.specialCarError = true;
            } else {
              this.receivedDMRVehicle = true;
            }
          },
          error: (error) => {
            this.errorInRegOrVinNumber = true;
            this.loadingRegOrVinNumber = false;
            this.receivedDMRVehicle = false;

            console.error(error);
            return;
          },
        });
    } else {
      this.loadingRegOrVinNumber = true;
      // Vin number
      this.dmrService
        .getVehicleByVINNumber(this.regOrVinNumber, this.requireAuth)
        .subscribe({
          next: (result: any) => {
            this.vehicleFromDmr = result.vehicleInfo;
            this.loadingRegOrVinNumber = false;

            if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
              this.receivedDMRVehicle = false;
              this.specialCarError = true;
            } else {
              this.receivedDMRVehicle = true;
            }
          },
          error: (error) => {
            this.errorInRegOrVinNumber = true;
            this.loadingRegOrVinNumber = false;
            this.receivedDMRVehicle = false;

            console.error(error);
            return;
          },
        });
    }
  }

  dmrScrape() {
    // Reg number
    this.loadingDmrScrape = true;
    if (this.regOrVinNumber.length == 7) {
      this.dmrService
        .scrapeVehicleByRegNumber(this.regOrVinNumber, this.requireAuth)
        .subscribe({
          next: (result: any) => {
            this.loadingDmrScrape = false;

            this.vehicleFromDmr = result.vehicle;

            if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
              this.receivedDMRVehicle = false;
              this.specialCarError = true;
            }
          },
          error: (error) => {
            this.loadingDmrScrape = false;
            console.error(error);
            return;
          },
        });
    } else {
      // Vin number
      this.dmrService
        .scrapeVehicleByVINNumber(this.regOrVinNumber, this.requireAuth)
        .subscribe({
          next: (result: any) => {
            this.loadingDmrScrape = false;

            this.vehicleFromDmr = result.vehicleInfo;

            if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
              this.receivedDMRVehicle = false;
              this.specialCarError = true;
            }
          },
          error: (error) => {
            this.loadingDmrScrape = false;
            console.error(error);
            return;
          },
        });
    }
  }

  start() {
    this.loadingDealers = true;
    this.dealerService.getDealers(this.requireAuth).subscribe({
      next: (result) => {
        this.loadingDealers = false;
        this.dealers = result.dealers
          // Sort and set "ASG Care A/S" as the first option
          .sort((a: Dealer, b: Dealer) => a.name.localeCompare(b.name))
          .sort((a: Dealer, b: Dealer) =>
            b.name === 'ASG Care A/S' ? 1 : a.name === 'ASG Care A/S' ? -1 : 0,
          );

        // Set ASG Care A/S to defult selected
        this.selectedDealer = {
          name: this.dealers[0].name,
          value: this.dealers[0].id,
        };

        this.dropdown_dealers = this.dealers.map((item) => {
          return { name: item.name, value: item.id };
        });

        ++this.activeStep;
      },
      error: (error) => {
        this.loadingDealers = false;
        this.libUtilityService.notification(
          'error',
          'Fejl',
          'Der er sket en fejl - Kontakt os på support@asgcare.dk',
        );

        return;
      },
    });
  }

  previousStep() {
    if (this.activeStep !== 0) {
      --this.activeStep;
    }
    // if the activeStep is 2, where we show prices, we reset the number of steps
    if (this.activeStep === 2) {
      this.numberOfSteps = 7;
    }
  }

  dropdownOutput(item: any, dropdownId: string) {
    if (dropdownId == 'price') {
      this.selectedPrice = item;
      this.errorInCarPrice = this.selectedPrice.value == 0 ? true : false;
    }
    if (dropdownId == 'dealer') {
      this.selectedDealer = item;
      this.errorInDealer = this.selectedDealer.value == 0 ? true : false;
    }
  }

  validateBasicInfo() {
    this.errorInHelvetiaPrice = false;

    this.errorInPurchasedOrLeased = false;
    this.errorInCustomerAge = false;
    this.errorInZipCode = false;
    this.errorInCarPrice = false;
    this.errorInDealer = false;

    if (
      !this.receivedDMRVehicle ||
      !this.regOrVinNumber.replace(/\s/g, '') ||
      this.regOrVinNumber.replace(/\s/g, '').length < 7 ||
      !this.carOwnershipType ||
      !this.zipCode ||
      !(this.zipCode > 1000 && this.zipCode < 10000) ||
      !this.customerAge ||
      this.customerAge > 120 ||
      this.customerAge < 18 ||
      this.selectedPrice.value === 0 ||
      this.selectedDealer.value === 0
    ) {
      this.errorInRegOrVinNumber =
        !this.regOrVinNumber ||
        this.regOrVinNumber.replace(/\s/g, '').length < 7 ||
        !this.receivedDMRVehicle;
      this.errorInPurchasedOrLeased = !this.carOwnershipType.trim();
      this.errorInCustomerAge =
        !this.customerAge || this.customerAge > 120 || this.customerAge < 18;
      this.errorInZipCode =
        !this.zipCode || !(this.zipCode > 1000 && this.zipCode < 9999);
      this.errorInCarPrice = this.selectedPrice.value === 0;
      this.errorInDealer = this.selectedDealer.value === 0;
      return;
    }

    this.getInsurancePrices();
  }

  getInsurancePrices() {
    this.errorInHelvetiaPrice = false;

    this.loadinginsuranceInfo = true;

    if (this.customerAge && this.zipCode) {
      const insuranceInfo: HelvetiaPriceRequest = {
        customerType: 'private', // private/business
        driverAge: this.customerAge,
        secondDriverAge: null,
        financingType: this.carOwnershipType,
        postCode: this.zipCode.toString(),
        vehiclePrice: this.selectedPrice.value,
        vehicleHorsepower: this.vehicleFromDmr.horsepower,
        vehicleExpectedYearlyMileage: 14999,
        vehicleClaims: 0,
        isPrivatePrimaryDriver: true,
      };

      this.helvetiaService.getInsurancePrices(insuranceInfo).subscribe({
        next: (result: any) => {
          if (!result.prices.error) {
            this.helvetiaVariants = result.prices;
            // Sets the "restværdi" slider  to the deafult index
            this.selectedHelvetiaVariant = this.helvetiaVariants[1]; // Silver

            this.helvetiaMonthlyPrice = this.libUtilityService.getHelvetiaPrice(
              this.selectedHelvetiaVariant.coverages,
              'monthly',
            );
            this.helvetiaYearlyPrice = this.libUtilityService.getHelvetiaPrice(
              this.selectedHelvetiaVariant.coverages,
              'yearly',
            );
          } else {
            this.errorInHelvetiaPrice = true;
          }

          /// #################################################################

          this.loadinginsuranceInfo = false;
          this.errorIngetInsurancePrices = false;
          this.activeStep++;
        },
        error: (error) => {
          this.loadinginsuranceInfo = false;
          this.errorIngetInsurancePrices = true;
          this.libUtilityService.notification(
            'error',
            'Fejl',
            'Der er sket en fejl - Kontakt os på support@asgcare.dk',
          );
          return;
        },
      });
    }
  }

  pickHelvetiaPrice(goToNextStep: boolean) {
    if (goToNextStep) {
      ++this.activeStep;
    }

    //create draft
    const vin = this.vehicleFromDmr.vin;
    this.helvetiaService.createHelvetiaDraft(vin, this.requireAuth).subscribe({
      next: (result) => {},
      error: (error) => {
        console.error(error);

        return;
      },
    });
  }

  checkIfSpecialCarsBrand(brand: string) {
    const specialCarsBrands = [
      'ASTON MARTIN',
      'ALPINA',
      'BENTLEY',
      'BRABUS',
      'BUGATTI',
      'CORVETTE',
      'FERRARI',
      'HUMMER',
      'LAMBORGHINI',
      'MASERATI',
      'MAYBACH',
      'MERCEDES-MAYBACH',
      'MCLAREN',
      'ROLLS-ROYCE',
      'DE TOMASO',
      'KOENIGSEGG',
      'PAGANI',
      'FISKER',
      'ZENVO',
      'RUF',
      'RIMAC',
    ];

    return specialCarsBrands.includes(brand);
  }

  validateCustomerAge() {
    if (this.customerAge && this.customerAge.toString().length >= 2) {
      if (this.customerAge > 120 || this.customerAge < 18) {
        this.errorInCustomerAge = true;
        this.isCustomerAgeValid = false;
      } else {
        this.errorInCustomerAge = false;
        this.isCustomerAgeValid = true;
      }
    } else {
      this.errorInCustomerAge = false;
      this.isCustomerAgeValid = false;
    }
  }

  validateZipCode() {
    if (this.zipCode && this.zipCode.toString().length >= 4) {
      if (this.zipCode > 9999) {
        this.errorInZipCode = true;
        this.isZipcodeValid = false;
      } else {
        this.errorInZipCode = false;
        this.isZipcodeValid = true;
      }
    } else {
      this.errorInZipCode = false;
      this.isZipcodeValid = false;
    }
  }

  priceFilterFunction = (option: any, selectedValue: string): boolean => {
    if (!selectedValue.trim()) {
      return option;
    }

    const [lowerBound, upperBound] = option.name.split(' - ');

    const upperBoundNumber = parseFloat(
      upperBound.replace(' kr.', '').replace(/\./g, ''),
    );
    const lowerBoundNumber = parseFloat(
      lowerBound.replace(' kr.', '').replace(/\./g, ''),
    );

    const selectedNumber = parseFloat(selectedValue.replace(/\./g, ''));

    /* Retruns options where the serach values is included or is in range  */
    return (
      (selectedNumber >= lowerBoundNumber &&
        selectedNumber <= upperBoundNumber) ||
      option.name.includes(selectedValue.toLowerCase())
    );
  };
}
