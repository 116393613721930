import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'projects/shared/src/environments/environment';
import { Router } from '@angular/router';
import { User } from 'projects/shared/src/public-api';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  // TODO: Rename to Auth
  constructor(private http: HttpClient, private router: Router) {
    this.userProfileChange.subscribe((up) => {
      this.setActiveUserProfile(up);
    });
  }

  login(username: string, password: string): Observable<any> {
    const postData = { email: username, password: password };

    return this.http.post<any>(environment.apiLogin, postData).pipe(
      map((result) => {
        console.log('### Login', result);

        // Set a active user
        this.setOriginalUserProfile(result.user);
        this.setActiveUserProfile(result.user);

        // Set the orgianl and token and thats as the aktive token on login
        const accessToken: string = JSON.stringify(result.accessToken);
        const refreshToken: string = JSON.stringify(result.refreshToken);
        this.setOriginalToken(accessToken);
        this.setActiveToken(accessToken);
        this.setRefreshToken(refreshToken);
        //this.refreshToken = result.refreshToken;
        return result.user;
      }),
      catchError((error) => {
        console.error('Error in login request:', error);
        return throwError(() => error);
      })
    );
  }

  refreshAccessToken(token: string): Observable<any> {
    const postData = { refreshToken: token };
    console.log('refreshToken');

    return this.http.post<any>(environment.apiRefreshToken, postData).pipe(
      map((result) => {
        console.log(result);
        const accessToken: string = JSON.stringify(result.accessToken);
        const refreshToken: string = JSON.stringify(result.refreshToken);
        this.setRefreshToken(refreshToken);
        this.setActiveToken(accessToken);
        return result.accessToken;
      }),
      catchError((error) => {
        console.error('Error in login request:', error);
        return throwError(() => error);
      })
    );
  }

  isTokenExpired(accessToken: string) {
    return this.helper.isTokenExpired(accessToken);
  }

  helper = new JwtHelperService();

  checkForRenewedToken(result: any) {
    // Check for "renewedToken" token attbute in request
    const renewedTokenExist: boolean = result.hasOwnProperty('renewedToken');
    // if there is a renewedToken - update current token
    if (renewedTokenExist) {
      const tokenString: string = JSON.stringify(result.renewedToken);

      this.setActiveToken(tokenString);
    }
  }

  clearTokens() {
    localStorage.removeItem('originalToken');
    localStorage.removeItem('originalUserProfile');
    localStorage.removeItem('activeToken');
    localStorage.removeItem('activeUserProfile');
    localStorage.removeItem('impersonateToken');
    localStorage.removeItem('impersonateUserProfile');
    localStorage.removeItem('activeRefreshToken');
  }

  resetPassword(email: string): Observable<any> {
    const postData = { email: email };
    return this.http.post<any>(environment.apiResetPassword, postData).pipe(
      map((result) => {
        return result;
      }),
      catchError((error) => {
        console.error('Error in login request:', error);
        return throwError(() => error);
      })
    );
  }

  /////////////////////////////
  userProfileChange: Subject<User> = new Subject<User>();
  refreshToken: string = '';

  private readonly originalTokenKey = 'originalToken';
  private readonly originalUserProfileKey = 'originalUserProfile';

  private readonly impersonateTokenKey = 'impersonateToken';
  private readonly impersonateUserProfileKey = 'impersonateUserProfile';

  private readonly activeTokenKey = 'activeToken';
  private readonly activeRefreshTokenKey = 'activeRefreshToken';
  private readonly activeUserProfileKey = 'activeUserProfile';

  // Original User
  setOriginalToken(token: string) {
    localStorage.setItem(this.originalTokenKey, token);
  }

  getOriginalToken(): string | null {
    let token = localStorage.getItem(this.originalTokenKey);
    if (token != null) {
      token = JSON.parse(token);
    }
    return token;
  }

  setOriginalUserProfile(profile: any) {
    localStorage.setItem(this.originalUserProfileKey, JSON.stringify(profile));
  }

  getOriginalUserProfile(): any | null {
    const profile = localStorage.getItem(this.originalUserProfileKey);
    return profile ? JSON.parse(profile) : null;
  }

  // Impersonate User
  setImpersonateToken(token: string) {
    localStorage.setItem(this.impersonateTokenKey, token);
  }

  getImpersonateToken(): string | null {
    let token = localStorage.getItem(this.impersonateTokenKey);
    if (token != null) {
      token = JSON.parse(token);
    }
    return token;
  }

  setImpersonateUserProfile(profile: any) {
    localStorage.setItem(
      this.impersonateUserProfileKey,
      JSON.stringify(profile)
    );
  }

  getImpersonateUserProfile(): any | null {
    const profile = localStorage.getItem(this.impersonateUserProfileKey);
    return profile ? JSON.parse(profile) : null;
  }

  // Active access token
  setActiveToken(token: string) {
    localStorage.setItem(this.activeTokenKey, token);
  }

  getActiveToken(): string | null {
    let token = localStorage.getItem(this.activeTokenKey);
    if (token != null) {
      token = JSON.parse(token);
    }

    return token;
  }

  // Active refresh token
  setRefreshToken(token: string) {
    localStorage.setItem(this.activeRefreshTokenKey, token);
  }

  getRefreshToken(): string | null {
    let token = localStorage.getItem(this.activeRefreshTokenKey);
    if (token != null) {
      token = JSON.parse(token);
    }

    return token;
  }

  setActiveUserProfile(profile: any) {
    localStorage.setItem(this.activeUserProfileKey, JSON.stringify(profile));
  }

  getActiveUserProfile(): any | null {
    const profile = localStorage.getItem(this.activeUserProfileKey);
    return profile ? JSON.parse(profile) : null;
  }

  // Logout function
  logout() {
    const impersonateToken = this.getImpersonateToken();
    const impersonateUserProfile = this.getImpersonateUserProfile();

    if (impersonateToken && impersonateUserProfile) {
      localStorage.removeItem('impersonateToken');
      localStorage.removeItem('impersonateUserProfile');

      const originalToken = this.getOriginalToken();
      const originalUserProfile = this.getOriginalUserProfile();
      if (originalToken && originalUserProfile) {
        const tokenString: string = JSON.stringify(originalToken);

        this.setActiveToken(tokenString);
        this.setActiveUserProfile(originalUserProfile);
        //  this.userProfileChange.next(originalUserProfile); // Emit user profile change

        this.userProfileChange.next(originalUserProfile);
        this.router.navigate(['/portal/brugere']);
        // Redirect to users
      }
    } else {
      this.clearTokens();
      this.router.navigate(['/login']);
    }
  }
}
