import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { environment } from 'projects/shared/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CvrService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private loginService: LoginService
  ) {}

  // This can be called with or without auth check - deafult is true
  lookupCVR(cvr: string, requireAuth: boolean = true): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Check if we should check for auth token
    if (requireAuth) {
      // Check for an active token
      const myToken = this.loginService.getActiveToken();

      if (!myToken) {
        console.error('Error with token in lookupCVR');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }
      // If a token is available, proceed with the API call
      headers = headers.set('Authorization', `Bearer ${myToken}`);
    }

    return this.http
      .get<any>(`${environment.apiGetCompanyInfoFromCVR}${cvr}`, {
        headers,
      })
      .pipe(
        map((result) => {
          if (requireAuth) {
            // Check if the response contains a renewed token attribute
            // If found, update the active token
            this.loginService.checkForRenewedToken(result);
          }
          return result;
        }),
        catchError((error) => {
          // Handle errors, particularly 401 Unauthorized (Expired Token)
          if (requireAuth && error.status == 401) {
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
          }

          console.error(error);
          return throwError(() => error);
        })
      );
  }

  companyWithSubsidiary(cvr: string): Observable<any> {
    // Check for an active token
    const myToken = this.loginService.getActiveToken();

    // If a token is available, proceed with the API call
    if (myToken) {
      // Set headers including the token
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${myToken}`,
      });

      // Make the HTTP request
      return this.http
        .get<any>(environment.apiGetCompanyWithSubsidiaryFromCVR + cvr, {
          headers,
        })
        .pipe(
          map((result) => {
            // Check if the response contains a renewed token attribute
            // If found, update the active token
            this.loginService.checkForRenewedToken(result);
            return result;
          }),
          catchError((error) => {
            // Handle errors, particularly 401 Unauthorized (Expired Token)
            if (error.status == 401) {
              // Redirect to the login page if token is expired
              this.loginService.clearTokens();
              this.router.navigate(['/login']);
            }

            console.error(error);
            // Propagate the error
            return throwError(() => error);
          })
        );
    } else {
      // No token available, log error and redirect to login page
      console.error('Error with token in getUsers');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      // Return null wrapped in an observable
      return of(null);
    }
  }
}
